import { Autoplay, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

export const CAROUSEL_BASE_DEFAULT_CONFIG: SwiperOptions = {
  modules: [Pagination, Autoplay],
  pagination: {
    enabled: true,
    clickable: true,
    dynamicBullets: true,
    dynamicMainBullets: 5,
  },
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true,
  },
};
