import { ChangeDetectionStrategy, Component, effect, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselItemBase } from './carousel-item-base.model';
import { SwiperDirective } from '@core/directives/swiper.directive';
import { Swiper, SwiperOptions } from 'swiper/types';
import { CAROUSEL_BASE_DEFAULT_CONFIG } from './carousel-base.default-config';

@Component({
  selector: 'cgw-carousel-base',
  standalone: true,
  imports: [CommonModule, SwiperDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './carousel-base.component.html',
  styleUrl: './carousel-base.component.scss',
})
export class CarouselBaseComponent<T, CI extends CarouselItemBase<T>> {
  //#region I/O
  public readonly items = input.required<CI[]>();
  public readonly config = input<SwiperOptions>(CAROUSEL_BASE_DEFAULT_CONFIG);
  //#endregion

  public readonly instance = signal<Swiper | undefined>(undefined);

  constructor() {
    this.manageSlideUpdates();
  }

  public slideTo(index: number): void {
    if (this.instance()?.slides == null) return;

    this.instance().slideTo(index);
  }

  public previousSlide(): void {
    if (this.instance().isBeginning) {
      this.slideTo(this.instance().slides.length - 1);
      return;
    }

    this.instance().slidePrev();
  }

  public nextSlide(): void {
    if (this.instance().isEnd) {
      this.slideTo(0);
      return;
    }

    this.instance().slideNext();
  }

  private manageSlideUpdates(): void {
    effect(() => {
      if (!this.items() || !this.instance()) return;

      this.instance().updateSlides();
    });
  }
}
