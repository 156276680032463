@if (items()?.length > 0) {
  <div
    class="swiper"
    cgwSwiper
    [config]="config()"
    (onInstance)="instance.set($event)">
    <div class="swiper-wrapper">
      @for (slide of items(); track slide.id) {
        <div
          class="swiper-slide"
          [attr.data-swiper-autoplay]="slide.autoplayDelay">
          <ng-container
            [ngTemplateOutlet]="slide.templateRef"
            [ngTemplateOutletContext]="slide.templateContext" />
        </div>
      }
    </div>

    @if (config().pagination === true || config().pagination?.['enabled']) {
      <div class="pagination-container">
        <div class="swiper-pagination"></div>
      </div>
    }
  </div>
}
