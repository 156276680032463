import { TemplateRef } from '@angular/core';

export class CarouselItemBase<T, C = any> {
  public readonly id: number = this._item.id;
  public readonly data: T = this._item.data;
  public readonly templateRef: TemplateRef<C> = this._item.templateRef;
  public readonly templateContext: Record<string, any> = this._item.templateContext;
  public readonly autoplayDelay?: number = this._item.autoplayDelay ?? null;

  constructor(private _item: Omit<CarouselItemBase<T, C>, '_item'>) {}
}
