import { Directive, effect, ElementRef, input, output } from '@angular/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[cgwSwiper]',
  standalone: true,
})
export class SwiperDirective {
  //#region I/O
  public readonly config = input<SwiperOptions>({});

  public readonly onInstance = output<Swiper>();
  //#endregion

  constructor(private _swiperElementRef: ElementRef<HTMLElement>) {
    this.manageConfigs();
  }

  private manageConfigs(): void {
    effect(() => {
      const config = this.config();

      if (
        config.pagination === true ||
        (config.pagination?.['enabled'] && !config.pagination['el'])
      ) {
        config.pagination = Object.assign({}, config.pagination, { el: '.swiper-pagination' });
      }

      this.onInstance.emit(new Swiper(this._swiperElementRef.nativeElement, config));
    });
  }
}
