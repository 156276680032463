import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';

export interface IQueryImages {
  xxl: string;
  xl: string;
  lg: string;
  md: string;
  sm: string;
  xs: string;
}

@Injectable({
  providedIn: 'root',
})
export class BootstrapQueryService {
  private readonly CUSTOM_BREAKPOINTS: Array<[string, string]> = [
    ['xxl', '(min-width: 1400px)'],
    ['xl', '(min-width: 1200px)'],
    ['lg', '(min-width: 992px)'],
    ['md', '(min-width: 768px)'],
    ['sm', '(min-width: 576px)'],
    ['xs', '(max-width: 575.98px)'],
  ];
  private readonly CUSTOM_BREAKPOINTS_MAP = new Map<string, string>(this.CUSTOM_BREAKPOINTS);

  constructor(private breakpointObserver: BreakpointObserver) {}

  public getImageQuery(images: IQueryImages): Observable<string | undefined> {
    const querysObserve = this.CUSTOM_BREAKPOINTS.filter(([key]) => !!images[key]);
    const imageByQuery = querysObserve.map(([key, value]) => [value, images[key]]);

    return this.breakpointObserver.observe(querysObserve.map(([_, value]) => value)).pipe(
      map((result) => {
        const imageMatch =
          imageByQuery.find(([imgKey]) => !!result.breakpoints[imgKey])?.[1] ??
          imageByQuery[0]?.[1];

        return imageMatch ? `url(${encodeURI(imageMatch)})` : undefined;
      }),
    );
  }

  public getQueries(): Observable<string | undefined> {
    return this.breakpointObserver.observe(this.CUSTOM_BREAKPOINTS.map(([_, query]) => query)).pipe(
      map((result) => {
        return (
          Array.from(this.CUSTOM_BREAKPOINTS_MAP.keys()).find(
            (key) => result.breakpoints[this.CUSTOM_BREAKPOINTS_MAP.get(key)],
          ) ?? undefined
        );
      }),
    );
  }
}
